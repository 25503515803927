.mb {
  margin-bottom: 20px;
}

.mr {
  margin-right: 10px;
}

.padding {
  padding: 0 10px;
}

.panelHeader {
  color: #FFF;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  background-color: #F0F0F0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

.boxHeader {
  background-color: #FAFAFA;
  padding: 10px;
}

.table {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.row {
  border: 1px solid #DDD;
  box-sizing: border-box;
}

.cell {
  border: 1px solid #F0F0F0;
  overflow-wrap: break-word;
  box-sizing: border-box;
  background-color: #E6F7FF;
}

.leftCol {
  background: #E6F7FF;
  width: 240px;
  display: flex;
  align-items: center;
}

.rightCol {
  width: calc(100% - 240px);
}

.cell2 {
  border: 1px solid #F0F0F0;
  overflow-wrap: break-word;
  box-sizing: border-box;
  background-color: #FFF;
}

.select {
  width: 300px;
}

.smallSelect {
  width: 150px;
}

.pushRight {
  margin-right: auto;
}

/* > new styles */

.actionsContainer {
  display: flex;
  column-gap: 16px;
}

.selectContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 4px;
  flex: 0 1 300px;
}

.smallSelectContainer {
  flex: 0 1 150px;
}

.selectLabel {
  white-space: nowrap;
  word-break: keep-all;
}

.selectItem {
  width: 100%;
}

.submitActionsContainer {
  flex: 1 0 250px;
  display: flex;
  flex-wrap: nowrap;
  padding-left: 20px;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 991px) {
  .actionsContainer {
    flex-wrap: wrap;
    row-gap: 8px;
  }

  .selectContainer {
    flex: 1 1 200px;
  }

  .smallSelectContainer {
    flex: 1 1 100px;
  }
}
