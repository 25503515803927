.mainContainer {
  width: 100%;
  height: 600px;
  position: relative;

  margin-bottom: 40px;
}

.cloudChart {
  position: absolute;
  top: 0;
  left: 50%;
  translate: -50% 0;
  width: 90%;
  height: 600px;
}

.noData {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px dashed #d9d9d9;
  border-radius: 16px;
}

:global(.words-item) {
  left: 0px;
  top: 0px;
  opacity: 1;
  transition: left 1s linear, top 1s linear, opacity .5s linear;
  cursor: pointer;
}

:global(.words-item)> :global(.tooltip) {
  display: none;
  height: auto;
  line-height: 16px;
  position: absolute;
  border: 2px solid #e60049;
  border-radius: 4px;
  background-color: #fff;
  font-size: 12px;
  color: #e60049;
  padding: 4px 8px;

  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
}

.cloudChart:has(> :global(.words-item-hover)) > :global(.words-item) {
  opacity: 0.2;
}

.cloudChart:has(> :global(.words-item-hover)) > :global(.words-item-hover) {
  opacity: 1;
}

.cloudChart:has(> :global(.words-item-hover)) > :global(.words-item-hover) > :global(.tooltip) {
  display: block;
}
