.tab {
  height: calc(100vh - 120px);
  overflow-y: scroll;
}

.margin {
  margin: 10px;
}

.mr {
  margin-right: 10px;
}

.mb {
  margin-bottom: 10px;
}

.box {
  padding: 10px;
  border: 1px solid #F0F0F0;
  margin-bottom: 10px;
}

.extra {
  margin-top: -10px;
  margin-left: 150px;
  color: rgba(0, 0, 0, 0.45);
}

.treeSelect {
  width: 300px;
}

.select {
  width: 235px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.red {
  color: red;
  margin-right: 5px;
}

.collapseBody {
  min-height: 50px;
  padding: 10px;
}

.collapse {
  margin: 10px 0;
  max-width: 1300px;
}

.btns {
  margin-top: 50px;
}

.txt {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  width: 100%;
  overflow-wrap: break-word;
  padding: 0 10px;
}

.center {
  text-align: center;
}

.hyperlink {
  color: #1890ff;
  cursor: pointer;
}

.dot {
  width: 10px;
  height: 10px;
  margin-right: 7px;
  border-radius: 50%;
}

.positive {
  background-color: #52C41A;
}

.neutral {
  background-color: #D9D9D9;
}

.negative {
  background-color: #FF4D4F;
}

.yellowBtn {
  background-color: #FADB14;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  border: none;
  color: #000;
}

.yellowBtn:active,
.yellowBtn:focus,
.yellowBtn::selection,
.yellowBtn:hover {
  background-color: #FADB14;
  color: #000;
}

.blockedModal :global(.ant-modal-content) {
  border-radius: 8px;
}

.blockedModal :global(.ant-modal-body) {
  padding: 0px;
}

.wordModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 16px;

  padding: 20px 24px;
}

.wordModalTitle {
  font-weight: 400;
}

.wordModalContent {
  font-size: 20px;
  font-weight: 700;
}

.wordModalFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.wordModalFooterButton {
  border-radius: 8px;
  width: 120px;
  line-height: 1.5;
}

.blockListModal :global(.ant-modal-content) {
  border-radius: 8px;
}

.blockListModal :global(.ant-modal-body) {
  padding: 0px;
}

.blockListModalContainer {
}

.blockListModalHeader {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 2px solid #d9d9d9;
}

.blockListModalTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
}

.closeIcon {
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.blockListModalContent {
  height: 400px;
  padding: 12px 24px;
  display: grid;
  grid-template-rows: auto 1fr;
}

.emptyBlockList {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
}

.blockListModalContentHeaderRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: center;
  justify-items: center;
}
.blockListModalContentHeaderRow .blockListModalContentRowItem {
  text-align: center;
  width: 100%;
  line-height: 2;
  font-weight: 700;
  border-bottom: 2px solid #d9d9d9;
  padding-bottom: 8px;
}

.blockListModalKeywordContainer {
  padding: 10px 0px;
  height: 100%;
  overflow: auto;
}

.blockListModalContentRow  {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: center;
  justify-items: center;
}

.blockListModalContentRowItem {
  text-align: center;
  width: 100%;
  line-height: 3;
  padding-bottom: 8px;
}


/* > feature rank table */

.featureRankTablePageContainer {
  padding-bottom: 20px;
}

.loading {
  position: relative;
  pointer-events: none;
}

.loading::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.02);
  z-index: 1000;
}
.downloadCloudTarget {
  padding: 10px 16px;
}

.pageTitle {
  margin: 0px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;
}

.cloudChartHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
}

.disabledHeader {
  opacity: 0;
  pointer-events: none;
}

.timeString {
  font-size: 12px;
}

.cloudChartActionsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
}

.disabledActionsContainer {
  opacity: 0;
  pointer-events: none;
}

.cloudChartActionsButton {
  padding: 10px 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collapseHeader {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 10px;
  padding-bottom: 20px;
}

.collapseHeader::after {
  content: '檢視數據列表';
  position: absolute;
  top: 0;
  left: 0;
  translate: calc(-100% - 10px) 0;
  word-break: keep-all;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  pointer-events: none;
}

.featureRankTablePageContainer :global(.ant-collapse-header) {
  padding: 0px;
  justify-content: flex-end;
}